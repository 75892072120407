import { getSession } from "next-auth/react"
import Head from 'next/head'
import { useRouter } from "next/router";
import { useState } from 'react'
import { toast } from "react-toastify";
import styled from 'styled-components';

import CompanyList from '@components/company-list';
import Stats from '@components/company-list/stats';

import { getCompaniesToProcess, isRole } from '@utils';

const Box = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.6em;
    overflow: hidden;
    background: #fff;
`
const StartButton = styled.a`
margin:0 0 2em;
font-size:.9em;
`
const StatsContainer = styled.div`
`

export default function Home({ currentUserId }: { currentUserId: string }) {

    const [isPreparingProcess, setIsPreparingProcess] = useState(false)
    const router = useRouter();

    async function startProcessing(userId: string) {
        if (isPreparingProcess) return;
        setIsPreparingProcess(true)
        const companies = await getCompaniesToProcess(userId, {
            forceRefresh: true
        })

        if (companies.length > 0) {
            router.push(`/companies/${companies[0]}`);
        }
        else {
            toast.warning("There are no companies to process")
            setIsPreparingProcess(false)
        }
    }

    if (!currentUserId) {
        return <><p className="text-center">401</p></>
    }

    return (
        <>
            <Head><title>Icebreaker</title></Head>
            <div className="container" style={{ width: 1100 }}>

                <StatsContainer>
                    <Stats />
                </StatsContainer>

                <div className='text-center'>
                    <StartButton
                        onClick={() => {
                            startProcessing(currentUserId)
                        }}
                        className="button-dark">{isPreparingProcess ? "Starting..." : <>
                            Start processing
                        </>}</StartButton>
                </div>

                <Box>
                    <CompanyList />
                </Box>
            </div>
        </>
    );
}

export async function getServerSideProps(context: any) {
    const { req } = context;
    const session = await getSession({ req });

    if (!session?.user?.uid) {
        return { props: {} }
    }

    if (!isRole(session, 'dealteam')) {
        return {
            redirect: { destination: "/portfolio" },
        };
    }

    return {
        props: {
            currentUserId: session?.user?.uid,
        }
    };
}