import styled from 'styled-components'
import useRequest from '@hooks/useRequest'

const Header = styled.h3`
margin:0;
`

const Warning = styled.span`
color:red;
font-weight:bold;
`

const Placeholder = styled.span`
    width: 1.1em;
    background: #d8d8d8;
    display: inline-block;
    vertical-align: middle;
    height: 0.8em;
    border-radius: 3px;
    `

function Number(data: any, prop: string) {
    if (data) {
        const number = data[prop];
        if (number === 0) {
            return <Warning>{number}</Warning>
        }
        return number;
    }
    else {
        return <Placeholder />
    }
}

export default function DbStats() {

    const { data, error } = useRequest<any>({ url: '/api2/stats/dashboard' }, {
        revalidateIfStale: false,
        revalidateOnFocus: false
    })

    if (error) {
        console.log(error);
        return <div>❌</div>
    }

    const { companies, autopopulation } = data || {};

    return <>
        <Header>
            Companies: {Number(companies, 'open')}
        </Header>
        <p>
            Added last 10 days:
            <br />
            🇸🇪 {Number(companies, 'swedishCompanies')} | 🇫🇮 {Number(companies, 'finnishCompanies')} | 🇪🇪 {Number(companies, 'estonianCompanies')}
        </p>
        {companies?.unpublishedSwedishCompanies || companies?.unpublishedFinnishCompanies || companies?.unpublishedEstonianCompanies ?
            <p>
                <br />
                Unpublished companies:
                <br />
                🇸🇪 {companies?.unpublishedSwedishCompanies} | 🇫🇮 {companies?.unpublishedFinnishCompanies} | 🇪🇪 {companies?.unpublishedEstonianCompanies}
            </p>
            : null
        }

        {autopopulation?.successRate ?
            <p>
                <img style={{ width: '.8em' }} src="/icons/gem.svg" /> LinkedIn autopopulation success rate: {autopopulation?.successRate}% ({autopopulation?.totalCount})
            </p>
            : null
        }

    </>
}